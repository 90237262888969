<template>
	<section ref="qrPanel" class="qr-section">
		<client-only>
			<div class="qr-panel">
				<div class="row">
					<form class="booking bookForm">
						<div class="columns column4">
							<date-picker
								v-model:value="state.arrival"
								:clearable="false"
								:disabled-date="disableArrivalDates"
								value-type="format"
								format="DD-MM-YYYY"
								:placeholder="$t('checkIn')"
								:lang="datepickerLang"
								@pick="pickArrival"
							/>
						</div>
						<div class="columns column4">
							<date-picker
								v-model:value="state.departure"
								:open="state.openDeparture"
								:disabled-date="disableDepartureDates"
								value-type="format"
								format="DD-MM-YYYY"
								:placeholder="$t('checkOut')"
								:default-value="state.pickedArrivalDate ?? new Date()"
								:lang="datepickerLang"
								@open="state.openDeparture = true"
								@close="state.openDeparture = false"
							/>
						</div>
						<div class="columns column4">
							<input type="submit" class="button book-now cta qr" :value="bookingForm.availability" />
						</div>
					</form>
				</div>
				<div class="row">
					<div class="columns column12">
						<span>
							<strong>{{ bookingForm.benefitsContent }}</strong>
						</span>
						<span v-for="benefit in bookingForm.benefit" :key="benefit.ID">
							<font-awesome-icon :icon="`fa fa-check`" size="1x" />
							{{ benefit.content }}
						</span>
					</div>
				</div>
			</div>
		</client-only>
	</section>
</template>

<script setup>
import DatePicker from 'vue-datepicker-next';
import 'vue-datepicker-next/index.css';
import datePickerTranslations from '~/config/datePicker.js';

const { locale } = useI18n();

defineProps({
	bookingForm: { type: Object, default: () => ({}) },
});

const state = reactive({
	arrival: null,
	departure: null,
	openDeparture: false,
	pickedArrivalDate: null,
});

const qrPanel = ref(null);

const datepickerLang = computed(() => datePickerTranslations[locale] ?? datePickerTranslations.en);

const pickArrival = (date) => {
	state.departure = '';
	state.pickedArrivalDate = date;
	state.openDeparture = true;
};

const disableArrivalDates = (date) => {
	const today = new Date();

	return date < today.setDate(today.getDate() - 1);
};

const disableDepartureDates = (date) => {
	if (!state.pickedArrivalDate) {
		return disableArrivalDates(date);
	}

	return date <= state.pickedArrivalDate;
};

onMounted(() => {
	qrPanel.value.classList.add('mounted');
});
</script>

<style>
.mx-datepicker {
	width: 100%;
}

.mx-input {
	margin: 3px 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 15px !important;
	height: 50px !important;
	border: 1px solid rgb(0 0 0 / 5%) !important;
}
</style>

<style lang="scss" scoped>
.qr-section {
	text-align: center;
	margin: 0 auto;
	position: absolute;
	bottom: 100px;
	left: 0;
	right: 0;
	padding: 0;
	float: left;
	width: 100%;
	max-width: 920px;
	z-index: 10;
}

.qr-panel {
	float: none;
	padding: 20px;
	width: 100%;
	margin: 0 auto;
	display: inline-block;
	position: relative;
	z-index: 2;
	text-align: center;

	.row {
		width: 100%;
		background: rgba(255 255 255 / 90%);
		padding: 15px;

		&:last-child {
			background: #fff;
			padding: 15px 25px;
			font-size: 14px;

			.column12 {
				text-align: left;
			}

			span {
				margin: 0 5px 0 0;
			}
		}
	}

	.columns {
		padding: 10px;
	}

	form {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		justify-content: space-between;
	}
}

.qr-panel input,
.qr-panel select {
	margin: 3px 0 !important;
	width: 100% !important;
	padding: 15px 40px 15px 15px !important;
	height: 50px !important;
	border: 1px solid rgb(0 0 0 / 5%) !important;
}

.qr-panel .button {
	padding: 14px 10px !important;
	text-transform: uppercase;
	border: none !important;
	vertical-align: top;
}
</style>
